// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-urinary-tract-jsx": () => import("./../../../src/pages/about-the-urinary-tract.jsx" /* webpackChunkName: "component---src-pages-about-the-urinary-tract-jsx" */),
  "component---src-pages-helpful-resources-tsx": () => import("./../../../src/pages/helpful-resources.tsx" /* webpackChunkName: "component---src-pages-helpful-resources-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-treatment-options-jsx": () => import("./../../../src/pages/treatment-options.jsx" /* webpackChunkName: "component---src-pages-treatment-options-jsx" */),
  "component---src-pages-understanding-utuc-tsx": () => import("./../../../src/pages/understanding-utuc.tsx" /* webpackChunkName: "component---src-pages-understanding-utuc-tsx" */)
}

