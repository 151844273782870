module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://utuc.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-default-mdx-basic","short_name":"starter","start_url":"/","background_color":"#95B9C7","theme_color":"#95B9C7","display":"minimal-ui","icon":"src/images/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d84ff41837409f0af3f5607b5b8eda0d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-ML3PS9C","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"() => {\n          [\n            {\n              consent: {\n                default: {\n                  ad_storage: 'denied',\n                  analytics_storage: 'denied',\n                  wait_for_update: 500,\n                },\n              },\n            },\n            {\n              set: {\n                ads_data_redaction: true,\n              },\n            },\n          ].forEach(d => window['dataLayer'] && window['dataLayer'].push(d));\n          return {\n            platform: 'gatsby',\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    }]
